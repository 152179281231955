import auth from "@/middleware/auth";
import hasRoles from "@/middleware/hasRoles";
import isActivatedUser from "@/middleware/isActivatedUser";
import clearCartData from "@/middleware/clearCartData";
import checkIsVideoInWindow from "@/middleware/checkIsVideoInWindow";
import checkIsVideoMounted from "@/middleware/checkIsVideoMounted";
import hasMedicalLicence from "@/middleware/hasMedicalLicence";

const mainPageBreadCrumb = [{ name: "layouts.main_page", link: "Dashboard" }];

export default {
  path: "",
  meta: {
    middlewares: [
      auth,
      hasRoles,
      isActivatedUser,
      hasMedicalLicence,
      checkIsVideoInWindow,
      checkIsVideoMounted,
    ],
    roles: ["doctor"],
  },
  children: [
    {
      path: "referral/facility/:id?",
      name: "DoctorReferralFacility",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "doctor-referral-facility" */ "@/views/Auth/Doctor/Facility.vue"
        ),
    },
    {
      path: "settlements",
      name: "DoctorSettlements",
      component: () =>
        import(
          /* webpackChunkName: "doctor-settlements" */ "@/views/Doctor/Settlements.vue"
        ),
    },
    {
      path: "referral-create",
      name: "DoctorReferralCreate",
      meta: {
        extraMiddlewares: [clearCartData],
        breadcrumb: [
          ...mainPageBreadCrumb,
          { name: "layouts.examintons_list" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-referral-create" */ "@/views/Doctor/ReferralCreate.vue"
        ),
    },
    {
      path: "referral-visit",
      name: "DoctorReferralVisit",
      meta: {
        breadcrumb: [
          ...mainPageBreadCrumb,
          { name: "layouts.examintons_list" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-referral-add-visit" */ "@/views/Doctor/ReferralAddVisit.vue"
        ),
    },
    {
      path: "tests-to-describe/:describeId",
      meta: {
        breadcrumb: [
          ...mainPageBreadCrumb,
          { name: "layouts.examintons_list" },
        ],
      },
      children: [
        {
          path: "referral-create",
          name: "TestToDescribeReferralCreate",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "doctor-referral-create" */ "@/views/Doctor/ReferralCreate.vue"
            ),
        },
        {
          path: "referral-visit",
          name: "TestToDescribeReferralVisit",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "doctor-referral-add-visit" */ "@/views/Doctor/ReferralAddVisit.vue"
            ),
        },
      ],
    },
    {
      path: "meeting",
      meta: {
        breadcrumb: [
          ...mainPageBreadCrumb,
          { name: "layouts.examintons_list" },
        ],
      },
      children: [
        {
          path: "video/:meetingId",
          children: [
            {
              path: "referral-create",
              name: "MeetingReferralCreateVideo",
              component: () =>
                import(
                  /* webpackChunkName: "doctor-referral-create" */ "@/views/Doctor/ReferralCreate.vue"
                ),
            },
            {
              path: "referral-visit",
              name: "MeetingReferralVisitVideo",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "doctor-referral-add-visit" */ "@/views/Doctor/ReferralAddVisit.vue"
                ),
            },
          ],
        },
        {
          path: "phone/:meetingId",
          meta: {
            breadcrumb: [
              ...mainPageBreadCrumb,
              { name: "layouts.examintons_list" },
            ],
          },
          children: [
            {
              path: "referral-create",
              name: "MeetingReferralCreatePhone",
              component: () =>
                import(
                  /* webpackChunkName: "doctor-referral-create" */ "@/views/Doctor/ReferralCreate.vue"
                ),
            },
            {
              path: "referral-visit",
              name: "MeetingReferralVisitPhone",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "doctor-referral-add-visit" */ "@/views/Doctor/ReferralAddVisit.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "referral-edit/:id",
      name: "DoctorReferralEdit",
      component: () =>
        import(
          /* webpackChunkName: "doctor-referral-edit" */ "@/views/Doctor/ReferralCreate.vue"
        ),
    },
    {
      path: "referral-history",
      name: "DoctorReferralHistory",
      component: () =>
        import(
          /* webpackChunkName: "doctor-referral-history" */ "@/views/Doctor/ReferralHistory.vue"
        ),
    },
    {
      path: "tests-to-describe",
      name: "TestsToDescribe",
      component: () =>
        import(
          /* webpackChunkName: "tests-to-describe" */ "@/views/Doctor/TestsToDescribe.vue"
        ),
    },
    {
      path: "tests-to-describe/describe/:id",
      name: "TestsToDescribeDescribe",
      component: () =>
        import(
          /* webpackChunkName: "tests-to-describe-describe" */ "@/views/Doctor/TestsToDescribeDescribe.vue"
        ),
    },
    {
      path: "own-package/:id",
      name: "DoctorOwnPackage",
      props: (route) => ({ id: route.params.id * 1 }),
      meta: {
        breadcrumb: [
          ...mainPageBreadCrumb,
          { name: "layouts.examintons_list", link: "DoctorReferralCreate" },
          { name: "layouts.own_packages", link: "DoctorReferralCreate" },
          { name: ":name" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-own-package" */ "@/views/Doctor/OwnPackage.vue"
        ),
    },
    {
      path: "calendar",
      name: "DoctorCalendar",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-calendar" */ "@/views/Doctor/Calendar.vue"
        ),
    },
    {
      path: "visits",
      name: "DoctorVisits",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-visits" */ "@/views/Doctor/Visits.vue"
        ),
    },
    {
      path: "patient-list",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      name: "DoctorPatientList",
      component: () =>
        import(
          /* webpackChunkName: "doctor-patient-list" */ "@/views/Doctor/PatientList.vue"
        ),
    },
    {
      path: "patient/:id",
      name: "DoctorPatientCard",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-patient-card" */ "@/components/Layouts/Doctor/PatientCard.vue"
        ),
      children: [
        {
          path: "",
          name: "PatientInformation",
          component: () =>
            import(
              /* webpackChunkName: "patient-information" */ "@/views/Doctor/PatientCard/Information.vue"
            ),
        },
        {
          path: "appointments",
          name: "PatientAppointments",
          component: () =>
            import(
              /* webpackChunkName: "patient-appointments" */ "@/views/Doctor/PatientCard/Appointments.vue"
            ),
        },
        {
          path: "history",
          name: "PatientHistory",
          component: () =>
            import(
              /* webpackChunkName: "patient-history" */ "@/views/Doctor/PatientCard/ReferralHistory.vue"
            ),
        },
      ],
    },
    {
      path: "appointments/:appointeeId",
      name: "PatientAppointmentsId",
      props: true,
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
        roles: ["doctor", "super-clinic-admin"],
      },
      component: () =>
        import(
          /* webpackChunkName: "patient-appointments" */ "@/views/Doctor/PatientCard/AppointmentDetails.vue"
        ),
    },
    {
      path: "quick-call/:uid",
      name: "DoctorQuickCall",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-quick-call" */ "@/views/Doctor/QuickCall.vue"
        ),
    },
    {
      path: "call/video/:uid",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      name: "CallVideo",
      component: () =>
        import(/* webpackChunkName: "videocall" */ "@/views/Doctor/Call.vue"),
    },
    {
      path: "weight-loss/:chatId",
      name: "WeightLossChat",
      props: true,
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "weight-loss" */ "@/views/WeightLossChat.vue"
        ),
    },
    {
      path: "call/phone/:uid",
      name: "CallPhone",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name],
      },
      component: () =>
        import(/* webpackChunkName: "videocall" */ "@/views/Doctor/Call.vue"),
    },
    {
      path: "account",
      name: "Account",
      meta: {
        excludeMiddlewares: [hasMedicalLicence.name, isActivatedUser.name],
      },
      component: () =>
        import(
          /* webpackChunkName: "doctor-account" */ "@/components/Layouts/Doctor/DoctorAccount.vue"
        ),
      children: [
        {
          path: "",
          name: "AccountProfile",
          component: () =>
            import(
              /* webpackChunkName: "account-profile" */ "@/views/User/Profile.vue"
            ),
        },
        {
          path: "settings",
          name: "AccountSettings",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/Account.vue"
            ),
        },
        {
          path: "services",
          name: "AccountServices",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/Services.vue"
            ),
        },
        {
          path: "patient_appointment_hours",
          name: "AccountAppointmentHours",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/AppointmentHours.vue"
            ),
        },
        {
          path: "billing-information",
          name: "BillingInformation",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/BillingInformation.vue"
            ),
        },
        {
          path: "sms_notifications",
          name: "AccountSmsNotifications",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/SmsNotifications.vue"
            ),
        },
        {
          path: "ai-type-text",
          name: "AITypeText",
          component: () =>
            import(
              /* webpackChunkName: "account-settings" */ "@/views/User/AITypeText.vue"
            ),
        },
      ],
    },
  ],
};
