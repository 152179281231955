export default {
  account: {
    setting: "Account settings",
    settings: "Settings",
    performance: "Performance",
    default_price_list: "Default price list for laboratory tests",
    pwz: "medical licence",
    personal_data: "Personal information",
    email: "E-mail",
    password: "Password",
    your_password: "Your password",
    update_data: "Edit your data",
    change_password: "Change your password",
    blocked: "You have been blocked",
    contact_administrator: "Contact the administrator",
    your_data: "Your data",
    full_name: "Full name",
    contact_details: "Contact details",
    change_psw: "Change password",
    specialization: "Specialization",
    phone_number: "Phone number",
    collection_points: "Collection points",
    addres: "addres",
    profile: "My profile",
    about_me: "About me",
    services: "Services",
    patient_appointment_hours: "Availability",
    sms_notifications: "SMS notifications",
    ai_type_text: "AI type text",
    treated_diseases: "Treated diseases",
    education: "Education",
    internships: "Internships",
    languages: "Knowledge of languages",
    write: "Write",
    write_and_add: "Write and add...",
    contact: "Contact",
    hide_info:
      "This option will  hide your contact information from the visitor pages.  ",
    hide_date: "Hide date",
    show_date: "Show date",
    viewing_profile: "Viewing profile",
    hide_profile: "Hide date",
    show_profile: "Unhide profile",
    show_profile_info: "Your profile is inactive.",
    show_profile_info_2:
      "Patients cannot find you or schedule appointments with you.",
    profile_preview: "Profile preview",
    edit_photo: "Edit photo",
    upload_photo: "Upload another photo",
    your_account: "Your account",
    facility_and_billing_information: "Financial Information",
  },

  action: {
    change: "Change",
    see_details: "See details",
    see_doctor: "See doctor",
    see_referral: "See referral",
    go_back: "Go back",
    back: "Back",
    skip: "Skip",
    skip_and_submit: "Skip and Submit",
    submit: "Submit",
    next: "Next",
    save_changes: "Save changes",
    choose: "Select",
    selected: "Selected",
    continue: "Continue",
    save: "Save",
    clear: "Clear",
    return: "Return",
    filter: "Filter",
    withdraw_funds: "Withdraw funds",
    edit: "Edit",
    download: "Download",
    expand: "Expand",
    collapse: "Collapse",
    add: "Add",
    add_patient: "Add patient",
    search: "Search",
    cancel: "Cancel",
    show_more: "Show more",
    save_and: "Save and",
    exit: "Exit",
    delete: "Delete",
    close: "Close",
    resend: "Resend",
    send: "Send",
    sent: "Sent",
    copy: "Copy",
    copied: "Copied",
    start_call: "Start call",
  },

  search: {
    what_kind_of_visit_are_you_looking_for:
      "What kind of visit are you looking for?",
  },

  admin: {
    cities: {
      all: "All",
    },
    message: {
      title: "Title",
      message: "Content of the message",
      send: "Send a message",
      choose_doctors: "Select to whom you want to send the message",
      sent: "Your message has been sent to",
      recipients: "recipient(s)",
      recipients_number: "Number of recipients",
    },

    doctor: {
      search_patient: "Search patient",
      create: "Add doctor",
      doctor_data: "Doctor's data",
      company_data: "Company data",
      address: "Address",
      account_number: "Bank account number",
      first_name: "Name",
      last_name: "Surname",
      pwz: "Medical Licence",
      main_medical_category: "Medical category",
      email: "E-mail",
      registered_at: "Date of registration",
      phone_number: "Phone number",
      company_name: "Account Holder's Name",
      nip: "NIP",
      street: "The primary street address",
      house_number: "Optional details like suite, unit, building, floor.",
      flat_number: "Premises number",
      postal_code: "Postcode",
      city: "City/Town",
      country: "Country",
      edit: "Edit doctor",
      data_edit: "Edit data",
      referrrals_list: "Referrals list",
      send_message: "Send a message",
      status: "Status",
      full_name: "Full name",
      commission: "Salary",
      company: "Company",
      back_to_list: "Back to list of doctors",
      search_doctor: "Find a doctor",
      account_inactice: "The account is inactive",
      wait_for_activation:
        "Wait up to 24 hours for account validation from our staff",
      contact_info: "Contact us via chat from 6:00 a.m. to 11:30 p.m.",
      we_call_to_you: "We will call you",
      check_account_settings:
        "Check your account settings, add invoice details if you need them",
      go_to_settings: "Go to Settings",
      commission_percent: "% for doctor",
      patient_discount_percent: "% for patient",
      role: "Role",
      completed_profile: "Completed profile",
      card: "card",
    },

    invoices: {
      invoices: "Invoices",
      invoice_number: "Invoice number",
      amount: "Amount",
      doctor: "Doctor",
      account_number: "Account number",
      date_of_issue: "Date of issue",
      date_of_transfer: "Transfer date",
      download_all: "Download all",
      download_invoice: "Download an invoice",
      new: "New",
      paid: "Paid",
      amount_to_pay: "Amount to pay",
      select_month: "Select the month from which you want to download invoices",
      statuses: {
        new: "New",
        paid: "Paid",
        to_send: "To send",
        all: "All",
        sent: "Sent",
      },
      purchaser_name: "Purchaser",
      order_number: "Order number",
      date_of_order: "Date of order",
      date_execution: "Execution date",
      invoice_issued: "invoice_issued",
      mark_as_issued: "Mark as issued",
      mark_as_issued_confirm:
        "Confirm that you want to change the invoice status to issued",
      csv: "csv",
      status: "status",
      select_file: "Select file",
      add_file: "Add file",
    },

    promotions: {
      name: "Code Name",
      type: "Code Type",
      description: "Code Description",
      stepper: {
        next: "Next",
        prev: "Back",
        finish: "Finish",
      },
      discount_per_order: "Discount on the entire order",
      discount_on_product: "Discount on every matching product",
      buy_to_receive: "Buy X to receive Y",
      newsletter_discount: "Newsletter discount",
      numeric: "Numeric",
      alphanumeric: "Alphanumeric",
      alphabetical: "Alphabetical",
      discount: "Discount Amount",
      discount_type: "Type of Discount",
      is_personal_codes: "Personal Codes",
      is_omnibus: "Does the promotion fall under the omnibus?",
      has_slip_of_notepaper: "Do you display the slip of notepaper?",
      what_examination_are_you_looking_for:
        "What research are you looking for?",
      which_products_does_this_promotion_apply_to:
        "Which products does this promotion apply to?",
      select_all_examinations: "Select all tests",
      select_all_bundles: "Select all packages",
      select_nursing_services: "Select nursing services",
      select_consultation: "Select consultation",
      examination_name: "Examination Name",
      bundle_name: "Package Name",
      limited_promotion: "Limited promotion",
      number_of_promotions_for_the_code: "Number of promotions for the code",
      infinitely_many_for_the_code: "Infinitely many for the code",
      number_of_promotions_for_the_user: "Number of promotions for the user",
      base_amount: "Base amount",
      duration_of_the_promotion: "Duration of the promotion",
      from: "From",
      to: "To",
      enter_the_code: "Enter the code",
      new_code: "New code",
    },

    doctor_card: {
      status: "Status",
      referral_number: "Referral number",
      total_price: "Price",
      commission: "Salary",
      patient: "Patient",
      date_of_issue: "Date of issue",
      date_of_examination: "Date of test",
      city: "City",
      street: "Street",
      postal_code: "Post Code",
      birth_date: "Birth date",
    },

    visits: {
      type: "Type",
      status: "Status",
      number: "Visit number",
      patient: "Patient",
      date_of_start: "Date of start",
      date_of_end: "Date of end",
      assign_doctor: "Assign a doctor",
      change_doctor: "Change doctor",
      visit_number: "Visit no {number}",
      details: "Meeting details",
      no_details: "No details of the meeting",
    },
  },

  auth: {
    complete_your_doctor_profile: "Complete profile",
    go_to_the_main_dashboard: "Go to the main dashboard.",
    create_an_account: "Create an account",
    select_account_type: "Select Account Type",
    create_account: "Create account",
    enter_code: "SMS Verification",
    enter_code_information:
      "Confirm your identity and secure your account by entering the verification code sent to your phone number via SMS.",
    enter_code_below: " Enter this code below",
    enter_new_password: "Enter a new password for your account",
    email: "Email address",
    first_name: "Name",
    go_back_to_login: "Back to Login",
    have_account: "Already have an account?",
    last_name: "Surname",
    full_name: "First and last name",
    logout: "Logout",
    not_have_account: "Don't have an account yet?",
    not_receive_code: "The code didn't reach me.",
    not_remember_password: "Forgot your password?",
    password: "Password",
    password_confirm: "Repeat password",
    password_remind: "Remind password",
    password_reset: "Reset password",
    phone: "Phone number",
    pwz: "Medical licence No",
    register: "Register",
    register_go: "Go to registration",
    not_member: "Not a member yet?",
    join_us: "Join us now!",
    reset: "Reset password",
    send_reset_link: "We will send a password reset link to your email address",
    sent_reset_link:
      "We sent you an SMS with the verification code to the number",
    sent_reset_link_email:
      "We sent you an email with a link to reset your password.",
    sent_reset_link_error:
      "The link has been sent, wait a moment to receive a new link.",
    reset_link_expired: "Your password reset link has expired",
    sign_in: "Log in",
    sign_up: "Register",
    sms: "SMS code",
    specialist_panel: "to Your Dashboard",
    patient_panel: "to patient panel",
    verify: "Verify",
    pay_for_examinations: "to pay for tests",
    enter_email_and_password: "Enter your email and password,",
    to_create_account: "to create a portal account",
    enter_phone_number: "Enter your phone number",
    enter_pesel: "Enter your pesel",
    login_by_pesel: "Register using your pesel",
    login_by_phone: "Register using your phone number",
    identity_card: "Proof No.",
    pesel: "Personal ID Number",
    current_password: "Current password",
    set_password: "Set password",
    salary: "Salary",
    patient_account: "to patient account",
    country_code: "Country code",
    phone_number: "Phone number",
    services: {
      solutions: "Integrated Healthcare Solutions",
      service_1: "Order tailored medical tests for each patient.",
      service_2:
        "Real-time Result Notifications: Receive instant test results.",
      service_3: "Advanced Data Analysis: Use AI for deeper test insights.",
      service_4: "Engage with patients through video, phone, chat, and files.",
      service_5: "Boost earnings by offering consultations,test descriptions.",
    },
    checkboxes: {
      information_obligation: "Information Duty.",
      agree: "I declare that I have read and accept the",
      agree_marketing:
        "I agree to the processing of my personal data, i.e. e-mail address, for the purpose of sending the newsletter electronically",
      agree_contact: "I consent to being contacted by phone",
      agree_contact_expand:
        ", may contact me for the purpose of discussing the terms and conditions of the agreement by means of telecommunication end devices and automatic calling systems according to the Act of 16 July 2004. - Telecommunications Law, i.e., inter alia, by means of a mobile phone",
      terms_of_service: "Terms of Service",
      service: "service",
      and: "and",
      privacy_policy: "Privacy Policy.",
      agree_processing_my_personal_data:
        "I agree to the processing of my personal data indicated in the above form for the purpose of creating an account. ",
      agree_processing_my_personal_data_expand:
        "The data controller is Diagu Ltd. through the",
      platform: "platform",
      page_name: "GetLabTest.com",
      terms: "Terms",
    },
    commission_options: {
      d15p0: "15% for the doctor, 0% for the patient",
      d10p5: "10% for the doctor, 5% for the patient",
      d5p10: "5% for the doctor, 10% for the patient",
      d0p15: "0% for the doctor, 15% for the patient",
      other: "Other",
    },
    birth_date: "Date of birth",
    day: "Day",
    month: "Month",
    year: "Year",
    no_pesel: "No Personal ID number",
    enter_pesel_number: "Enter your Personal ID number",
    enter_birth_date: "Enter your date of birth",
    gender: {
      title: "Sex",
      m: "Man",
      f: "Woman",
    },
    choose_appropriate_account_category:
      "Choose the appropriate account category for registration",
    a_doctor_or_specialist: "Doctor or Specialist",
    individual_account_for_one_doctor:
      "Ideal for individual practitioners focusing on personalized care, managing their own appointments, patient interactions, and accessing specialized test ordering and results.",
    medical_entity: "Medical Entity",
    the_account_is_ideal_for_both_clinics_and_small_offices:
      "Perfect for clinics and offices, this account facilitates comprehensive management of multiple healthcare professionals, centralized scheduling, patient records management, and enhanced collaboration across various medical services and departments.",
    soon: "Soon",
    registration_process: "Registration process",
    step: "Step",
    your_data: "Your data",
    professional_details_and_account_setup:
      "Professional Details and Account Setup",
    enter_your_professional_information:
      "Enter your professional information and credentials to create your profile.",
    sms_veryfication: "SMS veryfication",
    collection_point: "Collection point",
    patient_hours: "Availability",
    billings_information: "Financial Information",
    doctor_services: "Service",
    define_your_services: "Define Your Services",
    personalize_your_service_portfolio:
      "For your convenience, we've pre-loaded your profile with three key remote consultation services: Phone and video conferencing options. These are just starting points – you have complete freedom to tailor them to your practice.",
    date: "Date",
    availability_schedule_setup: "Availability Schedule Setup",
    set_your_weekly_availability:
      "Set your weekly availability to optimize appointment bookings and ensure efficient patient service. This step is essential for managing your time and enhancing patient accessibility.",
    hours_closed: "Close",
    select_meeting_interval:
      "In what time intervals would you like to schedule patient appointments?",
    hours_required: "First, add some range of hours",
    invalid_hours: "Invalid time range",
    overlapping_hours: "Overlapping time range",
    edit_appoitment_hours: "Edit patient appointment hours.",
    add_hour_range: "Another appointment time",
    hours: {
      from: "From",
      to: "To",
      start: "Start",
      end: "End",
    },
    financial_information_completion: "Financial Information Completion",
    provide_your_banking_and_tax_details:
      "Provide your banking and tax details to facilitate secure and efficient payment processing for your services on the GetLabTest platform.",
    facility_name: "Facility name",
    position: "Position",
    roles: {
      doctor: "Doctor",
      clinic_owner: "Medical entity owner",
      clinic_worker: "Medical entity employee",
      clinic_it_specialist: "IT specialist",
    },
    name_medical_entity: "Name Facility",
    name_medical_entity_information:
      "Below add name and specialtys your Facility. That help to adding servises i next steps ",
    clinic_name: "Name Of Facility/Clinic",
  },

  cart: {
    selected_examinations: "Selected tests",
    no_examinations: "You have not yet selected any tests for referral.",
    nurse_service: "Nursing service",
    covid_service: "COVID Nursing Service",
    total: "Total",
    your_commission: "Your provision for Referrals",
    your_visit_commission: "Your provision for Visit",
    your_discount: "Discount",
    issue_referral: "Make a referral",
    edit_referral: "Edit referral",
    edit_referral_warning:
      "Editing this referral will issue a new referral and cancel the current referral.",
    pay_referral: "Pay",
    details: "Details",
    selected_collection_point: "Selected Test Centre",
    search_placeholder: "What lab test are you looking for?",
    next: "Next",
  },

  collection_facility: {
    collection_facility_title: "Collection points",
    collection_facility_text:
      "Set for which point you want to have the price list of laboratory tests displayed. you will be able to change it at any time in your profile.",
    search_placeholder: "Town or Post Code",
    current_location_error:
      "You did not allow your browser to download the location. Please try again.",
    hide_list: "Hide list",
    show_list: "Show list",
    show_filters: "Show filters",
    hide_filters: "Hide filters",
    show_on_map: "See on map",
    set_the_route: "Set the route",
    additional_options: "Additional options",
    selected_collection_points: "Selected Local Test Centres",
  },

  dashboard: {
    inaccessible_facility:
      "Your collection point is inactive or unable to perform tests.",
    inaccessible_facility_btn: "Change the collection point",
    commission_info:
      "The salary is the sum of the amounts payable for orders executed",
  },

  doctor_status: {
    blocked: "Blocked",
    active: "Active",
    inactive: "Inactive",
    deactivated: "Deactivated",
    all: "All",
  },

  empty_list: {
    no_data: "No data",
    referrals: "The referral list is empty.",
    visits: "The visits list is empty.",
    doctors: "The list of doctors is empty.",
    patients: "The patient list is empty.",
    invoices: "The list of invoices is empty.",
  },

  error: {
    photo: {
      size: "The file size is too large.",
    },
    gender: {
      required: "A sex is required.",
    },
    accept_data_processing: {
      accepted: "Consent is required.",
    },
    g_recaptcha_response: {
      required: "Recaptcha is invalid",
      recaptcha: "Recaptcha is invalid",
    },
    agree_contact: {
      accepted: "Consent is required.",
    },
    accept_terms: {
      accepted: "Consent is required.",
    },
    accept_marketing: {
      accepted: "Consent is required.",
    },
    code: {
      required: "A sms code is required.",
      incorrect: "The code you entered is invalid.",
    },
    email: {
      already_taken: "The email you provided has already been used.",
      credentials_not_match: "The credentials are invalid.",
      email: "The email must be a valid email address.",
      required: "Email is required.",
      selected_invalid: "The specified email does not exist in the database.",
    },
    pesel: {
      required: "Personal ID number is required.",
      already_taken: "The personal ID number provided has already been used.",
      digits: "The personal ID number must be 11 in length.",
      invalid: "Incorrect personal ID number.",
      required_if_empty: {
        birth_date: "Personal ID number or date of birth is required.",
      },
      required_when_not_present: {
        phone_number: "Phone number or pesel is required.",
      },
      incorrect: "We can't find the user with the given identity pesel.",
    },
    birth_date: {
      required: "Date of birth is required.",
      required_if_empty: {
        pesel: "Date of birth is required.",
      },
      must_be_date_after_or_equal:
        "Date of birth must be greater than or equal to 01/01 1900.",
      must_be_a_date_before: "It is not possible to choose this date of birth.",
      invalid_date: "Date of birth is not a valid date.",
      incorrect: "error.bith_date.incorrect",
    },
    first_name: {
      required: "First name is required.",
    },
    last_name: {
      required: "Last Name is required.",
    },
    main_medical_category_id: {
      required: "Please select categories.",
      selected_invalid: "The specified medical category could not be selected.",
    },
    current_password: {
      required: "The current password is required.",
      at_least_characters:
        "The current password must be at least 8 characters long.",
      invalid_format:
        "The current password must contain at least one lowercase letter, one uppercase letter, and one number.",
      incorrect: "The current password is incorrect.",
    },
    password: {
      required: "A password is required.",
      at_least_characters: "The password must be at least 8 characters long.",
      invalid_format:
        "The password must contain at least one lowercase letter, one uppercase letter, and one number.",
    },
    password_confirm: {
      required: "Password confirmation is required.",
      must_match: "The password and password confirmation must be the same.",
    },
    phone_number: {
      already_taken: "The phone number provided has already been used.",
      required: "Phone number is required.",
      digits: "The phone number must be 9 in length.",
      incorrect: "The phone number is incorrect.",
      accepted: "The phone number is incorrect.",
      choose_country: "Choose country",
      required_when_not_present: {
        pesel: "Phone number or pesel is required.",
      },
    },
    national_number: {
      required: "Phone number is required.",
    },
    phone_is_valid: {
      accepted: "Invalid phone number.",
    },
    pwz: {
      required: "Medical licence no. is required",
    },
    company_name: {
      required: "Account Holder's name is required.",
    },
    nip: {
      required: "NIP is required.",
      digits: "NIP must be 10 in length.",
    },
    street: {
      required: "Street is required.",
    },
    house_number: {
      required: "Building number is required.",
    },
    postal_code: {
      required: "Postcode is required.",
    },
    sort_code: {
      required: "Sort code is required.",
    },
    bank_name: {
      required: "Bank name is required.",
    },
    city: {
      required: "City is required.",
    },
    account_number: {
      required: "A bank account number is required.",
      invalid: "The bank account number is incorrect.",
    },
    agree1: {
      accepted: "Consent is required.",
    },
    identity_card: {
      required: "ID card number is required.",
    },
    title: {
      required: "Title is required.",
    },
    message: {
      required: "Message content is required.",
    },
    commission_option: {
      required: "The salary option is required.",
      selected_invalid: "The salary option must be one of the options above.",
    },
    is_verify: "An account with the given phone number already exists.",
    name: {
      required: "Name is required.",
      must_not_be_greater_than_255: "The name must not be longer than 255 ",
    },
    file: {
      must_not_be_greater_than_10240: "The file must not exceed 10MB.",
      must_be_a_file: "The file is required.",
    },
    promotions: {
      name: {
        required: "Code name is required.",
      },
      description: {
        required: "Code description is required.",
      },
      type: {
        required: "Code type is required.",
      },
      discount: {
        required: "Discount amount is required.",
        maxValue: "Discount amount cannot exceed 100%.",
        minValue: "Discount amount cannot be less than 0.",
      },
      type_discount: {
        required: "Discount type is required.",
      },
      use_limit_per_user: {
        required: "Number of promotions for the user is required.",
      },
      minimum_order_amount: {
        required: "Base amount is required.",
      },
      start_date: {
        beforeEndDate: "Start date must be earlier than end date.",
      },
      code_save_types: {
        required: "Method of generating code is required.",
      },
      file: {
        required: "File import is required.",
      },
      code: {
        required: "Code is required.",
      },
      amount_codes: {
        required: "Number of codes is required.",
      },
      length_limit: {
        required: "Number of code characters is required.",
      },
      code_type: {
        required: "Code format is required.",
      },
      suffix: {
        required: "Code suffix is required.",
      },
      prefix: {
        required: "Code prefix is required.",
      },
      packages: {
        minLength: "The promotion must include at least one test or package.",
      },
    },
    content: {
      required: "Content is required.",
    },
    change_date: {
      required: "Date is required",
    },
    end_date: {
      required: "Date is required",
    },
    time: {
      required: "A time is required.",
    },
    price: {
      required: "A price is required.",
      must_be_greater_than_0: "A price must be greather than 0.",
    },
    date_from: {
      conflict_meeting: "The date is already occupied",
    },
    about_me: {
      must_not_be_greater_than_1000: "The text must not exceed 1000 characters",
    },
    education: {
      must_not_be_greater_than_1000: "The text must not exceed 1000 characters",
    },
    internships: {
      must_not_be_greater_than_1000: "The text must not exceed 1000 characters",
    },
    role_id: {
      required: "Position is required.",
    },
  },

  error_page: {
    ups: "Oops, something went wrong. Wait a moment or try refreshing the page.",
    refresh: "Refresh",
  },

  examinations: {
    package: "Package",
    no_examinations: "No tests.",
    no_packages: "No packages.",
  },

  filters: {
    status: "Status",
    referral_id: "Referral ID",
    referral_number: "Referral number",
    patient: "Patient",
    doctor: "Doctor",
    price: "Test price",
    commission: "Salary",
    date_of_issue: "Date of issue",
    date: "Date",
    cost: "Cost of the test",
    patient_discount_percent: "Discount percentage",
    package: "Package",
    salary: "Salary",
    number_of_tests: "Quantity of tests",
    type: "Referral Type",
    referral_type: "Referral Type",
    role: "Role",
    visits: {
      number: "Visit number",
      date_of_visit: "Date of visit",
      type: "Visit type",
    },
  },

  patient_discount_percent: {
    zero: "0 %",
    five: "5 %",
    ten: "10 %",
    fifteen: "15 %",
    all: "All",
  },

  homepage: {
    "ai-chat": "AI chat",
    "super-clinic-admin": "Super Admin Clinic",
    doctor: "Doctor",
    patient: "Patient",
    admin: "Admin",
    clinic_owner: "Medical entity owner",
    clinic_worker: "Medical entity employee",
    clinic_it_specialist: "IT specialist",
    cms: "CMS",
    shop: "Shop",
    faq: "FAQ",
    about_us: "About us",
    contact: "Contact",
    catalog_of_examinations: "Catalog of tests",
    cart: "Cart",
    my_referrals: "My referrals",
    discount_1: "To",
    discount_2: "For all research packages",
    discount_3: "Check out",
    highest_quality_research: "Perform the highest quality tests in Poland",
    choose_from:
      "Choose from more than 3,500 tests, 68 test categories and packages.",
    join_special_actions:
      "Join special events, take advantage of special offers, create custom packages, and use a patient account with history and analysis of test results.",
    shortcuts: "Shortcuts",
    morphology: "Morphology",
    glucose: "Glucose",
    cpr: "CRP",
    creatynine: "Creatynine",
    potassium: "Potassium",
    iron: "Iron",
    mercury_in_the_blood: "Mercury in blood",
    collection_points_1: "Over",
    collection_points_2: "collection points",
    research_every_year_1: "Over",
    research_every_year_2: "million",
    research_every_year_3: "tests per year",
    years_on_the_market_1: "Over",
    years_on_the_market_2: "years",
    years_on_the_market_3: "on the market",
    what_examination_are_you_looking_for: "Enter a name",
    switch_roles: "Switch roles",
  },

  layouts: {
    issue_referral: "Make a referral",
    referral_history: "Referral history",
    patient_list: "Patient list",
    calendar: "Calendar",
    visits: "Visits",
    main_page: "Main page",
    examintons_list: "Catalog of tests",
    own_packages: "Own packages",
    tests_to_describe: "Tests to describe",
  },

  medical_categories: {
    specializations: "Specializations",
    allergist: "Allergist",
    pediatric_allergist: "Paediatric Allergist",
    andrologist: "Andrologist",
    anesthesiologist: "Anesthesiologist",
    angio_surgeon: "Angio-surgeon",
    angiologist: "Angiologist",
    audiologist: "Audiologist",
    bariatra: "Bariatrician",
    forensic_expert: "Forensic Expert",
    surgeon: "Surgeon",
    pediatric_surgeon: "Pediatric Surgeon",
    thoracic_surgeon: "Thoracic Surgeon",
    vascular_surgeon: "Vascular Surgeon",
    plastic_surgeon: "Plastic Surgeon",
    dental_surgeon: "Dental Surgeon",
    maxillofacial_surgeon: "Maxillofacial Surgeon",
    dermatologist: "Dermatologist",
    pediatric_dermatologist: "Paediatric Dermatologist",
    diabetologist: "Diabetologist",
    pediatric_diabetologist: "Paediatric Diabetologist",
    diagnostic: "Diagnostician",
    dietician: "Dietician",
    endocrinologist: "Endocrinologist",
    pediatric_endocrinologist: "Paediatric Endocrinologist",
    physiatrist: "Physiatrist",
    pediatric_physiatrist: "Paediatric Physiatrist",
    phlebologist: "Phlebologist",
    gastrologist: "Gastrologist",
    pediatric_gastrologist: "Paediatric Gastrologist",
    geneticist: "Geneticist",
    geriatrician: "Geriatrician",
    gynecologist: "Gynaecologist",
    pediatric_gynecologist: "Paediatric Gynaecologist",
    hematologist: "Haematologist",
    pediatric_hematologist: "Paediatric Haematologist",
    hepatologist: "Hepatologist",
    dental_hygienist: "Dental Hygienist",
    hypertensiologist: "Hypertensiologist",
    histopathologist: "Histopathologist",
    immunologist: "Immunologist",
    internist: "Internist",
    cardiac_surgeon: "Cardiac surgeon",
    cardiologist: "Cardiologist",
    pediatric_cardiologist: "Paediatric Cardiologist",
    laryngologist: "Laryngologist",
    pediatric_laryngologist: "Paediatric Laryngologist",
    infectious_diseases_physician: "Infectious Diseases Physician",
    occupational_medicine_physician: "Occupational Health Physician",
    doctor_of_emergency_medicine: "Emergency Medicine Physician",
    physician_of_medical_rehabilitation: "Medical Rehabilitation Physician",
    family_doctor: "Family Doctor",
    sports_doctor: "Sports Doctor",
    sports_medicine_physician: "Sports medicine physician",
    a_doctor_in_the_process_of_specialization:
      "Doctor in the course of specialisation",
    doctor_without_specialization: "Doctor without specialisation",
    logopedist: "Logopedist",
    nephrologist: "Nephrologist",
    pediatric_nephrologist: "Paediatric Nephrologist",
    neonatologist: "Neonatologist",
    neurosurgeon: "Neurosurgeon",
    pediatric_neurosurgeon: "Paediatric Neurosurgeon",
    neurologist: "Neurologist",
    pediatric_neurologist: "Paediatric Neurologist",
    ophthalmologist: "Ophthalmologist",
    pediatric_ophthalmologist: "Paediatric Ophthalmologist",
    oncologist: "Oncologist",
    pediatric_oncologist: "Paediatric Oncologist",
    optometrist: "Optometrist",
    orthodontist: "Orthodontist",
    orthopedist: "Orthopaedist",
    pediatric_orthopedist: "Paediatric Orthopaedist",
    orthoptist: "Orthoptist",
    osteopath: "Osteopath",
    pathomorphologist: "Pathomorphologist",
    pediatrician: "Paediatrician",
    perinatologist: "Perinatologist",
    nurse: "Nurse",
    obstetrician: "Obstetrician",
    midwife: "Midwife",
    proctologist: "Proctologist",
    prosthetist: "Prosthetist",
    psychiatrist: "Psychiatrist",
    child_psychiatrist: "Child Psychiatrist",
    psychologist: "Psychologist",
    child_psychologist: "Child psychologist",
    psycho_oncologist: "Psycho-oncologist",
    psychotherapist: "Psychotherapist",
    psychotraumatologist: "Psychotraumatologist",
    pulmonologist: "Pulmonologist",
    pediatric_pulmonologist: "Paediatric Pulmonologist",
    radiologist: "Radiologist",
    radiation_therapist: "Radiation Therapist",
    rheumatologist: "Rheumatologist",
    pediatric_rheumatologist: "Paediatric Rheumatologist",
    sexologist: "Sexologist",
    specialist_in_aesthetic_medicine: "Specialist in aesthetic medicine",
    dentist: "Dentist",
    pediatric_dentist: "Paediatric Dentist",
    transplantologist: "Transplantologist",
    coach: "Coach",
    ultrasonograph: "Ultrasonographer",
    urologist: "Urologist",
    pediatric_urologist: "Paediatric Urologist",
    venereologist: "Venereologist",
    main_category: "Category",
    list: "Specialisations",
    general_practitioner: "General Practitioner",
    pharmacist: "Pharmacist",
    all: "All",
  },

  messages: {
    delete: {
      success: "Successfully deleted.",
      failure: "An error occurred. Failed to delete.",
    },
    copy: {
      success: "Copied to clipboard.",
    },
    code: {
      resend: "The code was sent again.",
      resend_error: "An error occurred while submitting the code.",
      to_many_hour_attemps: "You used your 3 attempts. Try again in an hour.",
      to_many_day_attemps:
        "You used the maximum number of attempts for one day. Try again tomorrow.",
    },
    email: {
      resend: "The email was sent again.",
      resend_error: "An error occurred while submitting the email.",
    },
    edit: {
      success: "The changes were recorded.",
      failure: "An error occurred. Changes were not saved.",
    },
    edit_amd_send_seferral: {
      success: "The changes were saved and the referral was resent.",
    },
    save: {
      success: "Saved successfully.",
      failure: "An error occurred. Failed to save.",
    },
    sms: {
      success: "The text message was sent again.",
      failure: "An error occurred. The text message has not been sent again.",
    },
    sendMessage: {
      success: "Message sent.",
    },
    send: {
      success: "The message was sent.",
      failure: "An error occurred. The message could not be sent.",
    },
    payment_token: {
      failure:
        "An error occurred. Please try again or contact your administrator.",
    },
    permission: {
      failure: "It cannot be done.",
      forbidden: "This action is unauthorized.",
      unauthenticated: "Unauthenticated",
    },
    response: {
      failure: "An error occurred please try again.",
      validation: "Validation error occurred.",
      not_found: "Not Found.",
      forbidden: "This action is unauthorized.",
      unauthenticated: "Unauthenticated, please log in.",
    },
    download: {
      failure: "An error occurred while downloading the file.",
      no_invoice: "No invoices for the selected month.",
      no_files: "No files to download.",
    },
    next_patient: {
      not_found: "No found next patient",
    },
    prev_patient: {
      not_found: "No found previous patient",
    },
    test_to_describe: {
      save_as_a_draft: {
        success: "Successfully saved as a draft.",
        failure: "An error occurred. The test was not saved as a draft.",
      },
      send_to_patient: {
        success: "Successfully sent to the patient.",
        failure: "An error occurred. The test was not sent to the patient.",
      },
    },
    meet: {
      interview: {
        success: "Successfully saved interview",
      },
      recommendations: {
        success: "Successfully saved recommendations",
      },
    },
  },

  not_found_page: {
    error: "Error",
    not_found: "Page Not Found, But Health Insights Await!",
    not_found_content:
      "Seems like this page took a day off. Let's get you back on track to discover the wonders of digital healthcare.",
    go_back: "Go to the main page",
  },

  pages: {
    account: "Account settings",
    history_orders: "Referral history",
    my_settlements: "My accounts",
    doctors: "Doctors",
    referrals: "Referrals",
    invoices: "Invoices",
    your_referrals: "Your referrals",
    clientinvoices: "Customer invoices",
    raports: "Raporty",
    patient_card: "Patient's card",
    appointments: "Appointments",
    visits: "Visits",
    statistics: "Statistics",
    results: "Results",
    timetable: "Timetable",
    staff: "Staff",
    patients: "Patients",
    promotions: "Promotions",
    orders: "Orders",
  },

  patients: {
    edit_patient: "Edit selected patient",
    edit: "Edit patient",
    no_patients: "You have no patients yet.",
    patient_in_system_assign_to_another_doc:
      "The patient is already in our system",
    request_access_to_historical_test_results:
      "For better diagnostics, ask for access to historical test results",
    search_placeholder: "Name, Surname, Phone number",
    card: {
      visits_history: {
        empty_list: "No scheduled visits",
        show_summary: "Show summary",
        go_to_meeting: "Go to meeting",
        columns: {
          date: "Date",
          hour: "Time",
          patient: "Patient",
          service: "Service",
          visit_type: "Visit type",
          state: "Status",
          examinations_issued: "Tests issued",
        },
        visit_type: {
          phone: "Phone call",
          "phone-call": "Phone call",
          video: "Online",
          offline: "Office",
          null: "-",
        },
      },
    },
  },

  referral_status: {
    doctor: {
      issued: "Issued",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
      partial_results: "Partial Results",
      all: "All",
    },
    admin: {
      issued: "Issued",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
      partial_results: "Incomplete Results",
      all: "All",
      visit_data_missing: "Order does not have a visit date selected",
    },
    patient: {
      issued: "Issued",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
    },

    referrals: {
      canceled: "Canceled",
      issued: "Issued",
      paid: "Paid",
      partial_results: "Incomplete Results",
      completed: "Test Results - Done",
      being_edited: "Description - in progress",
      done: "Description - Done",
    },
  },

  empty_services: {
    packages: "No packages",
    examinations: "No tests",
    search: "No search term available",
  },

  own_packages: {
    examination_details: "Test details",
    package_details: "Package details",
    add_to_own_package: "Add to your own package",
    create_first_own_package:
      "Create your first custom package by typing its name below",
    edit_own_package: "Edit package name",
    my_bundles: "My Packages",
    your_own_packages: "Your own packages",
    add_to_new_own_package: "Add to a new custom package",
    create_own_package: "Create your package",
    create_your_own_package: "Create your own package",
    create_own_examination_package: "Create your custom package",
    other_examinations: "Other tests",
    all_examinations: "All tests",
    examination_packages: "Tests packages",
    own_packages: "Your Packages",
    create_first_own_examination_package: "Create your first package:",
    with_our_own_packages: "With our own packages:",
    faster_access: "You will have faster access to frequently sought tests",
    shorter_working_time:
      "Reducing the time spent on your work and issuing referrals",
    edit_package_name: "Edit package name",
    delete_package: "Delete package",
    remove_package: "Remove package",
    remove_test: "Remove test",
    add_to_cart: "Add to cart",
    add_to_cart_selected: "Add selected tests",
    add_new_own_package: "Add a new custom package",
    update_own_package: "Edit custom package",
    name_own_package: "Custom package name",
    delete_new_own_package: "Remove custom package",
    delete_new_own_package_confirm:
      'Are you sure you want to remove the "{name}" package?',
    delete_item_from_own_package: "Remove item from own package",
    delete_item_from_own_package_confirm:
      'Are you sure you want to remove "{test_name}" from "{package_name}"?',
    examinations: "tests",
    examinations_counter: "tests",
    add_examinations_to_package: "Add another test to the package",
    no_examinations_in_package_1:
      "You haven't added any tests to your package yet",
    no_examinations_in_package_2: "How to add tests to your own package?",
    no_examinations_in_package_3:
      "On the left (next to each name) is a purple list icon {icon} that lets you add the selected test to your package.",
    no_examinations_in_package_4:
      "Click on it and choose to which package you want to add the test. Remember that you can create an unlimited number of packages, it's just important to name them in a clear and distinctive way that suits you",
    no_examinations_in_package_5: "Go to the list with a selection of tests",
  },

  referrals: {
    make_a_referral: "Make a referral",
    collection_points: "Collection points",
    check_collection_point: "Check where your patient can get a referral",
    tests_results: "Tests results",
    tests_results_will_be_available:
      "Test results will be available after the referral",
    name_and_surname: "Name and surname",
    receive_VAT_invoice: "I want to receive a VAT invoice",
    to_receive_an_invoice:
      "To receive an invoice, please contact us at the following email address:",
    enter_study_name: "Enter a test name",
    referral: "Referral",
    number_short: "no.",
    examinations: "tests",
    date_of_issue: "date of issue",
    date_of_test: "date of test",
    exact_price:
      "You can find out the exact price by selecting a collection point",
    doctor: "doctor",
    number: "Order nr {number}",
    issued_on: "Issued on",
    see_results: "See test results",
    commissioned_examinations: "Commissioned tests",
    recommended_referrals: "Recommended referrals",
    recommended_visit: "Recommended visit",
    patient: "Patient",
    phone_number: "Phone No.",
    telephone_number: "Telephone number",
    pesel: "Personal ID number",
    payment: "Payment",
    commission: "Your provision",
    add_or_choose_patient:
      "Fill in the data for a new patient or find your patient in the list",
    add_patient: "Fill in the data for a new patient",
    edit_patient: "Edit patient",
    issue_referrals: "Make referrals",
    patient_list: "List of patients",
    my_patient_list: "List of my patients",
    choose_patient: "Select a patient from the list",
    full_name: "First and last name",
    search_patient: "Enter your name or phone number",
    search_referral_patient: "Enter first or last name",
    send_sms_again: "Send SMS again",
    edit_issued_referral: "Edit recommended referral",
    issued_referral_text_part1:
      "You have made a referral. We have sent a text message to the patient with instructions to complete the order.",
    issued_referral_text_part2:
      "You will be paid when the patient pays for the referral.",
    referral_for_examination: "Referral for laboratory testing",
    referral_for_examination_text:
      "Your patient can make a printed referral directly at the Diagnostic downloads point. Payment will be made at the point of downloads or online.",
    print_referral: "Print referral",
    collection_facilities: "Points of collection",
    collection_facilities_text:
      "Find out where your patient can complete the referral",
    see_collection_facilities: "See collection points.",
    examination_findings: "Results of test",
    examination_findings_text:
      "Test results will be available upon completion of the referral",
    unavailable: "Not available",
    waiting_for_payment: "Waiting for payment",
    waiting_for_commissions: "Waiting for the salary",
    price_online_payment:
      "Price for online payment at the website GetLabTest.com",
    price_facility_payment:
      "Price for payment at point of collection GetLabTest",
    commission_after_purchase:
      "The provision will be charged after the patient buys a referral",
    no_referrals: "You do not have referrals made.",
    issue_referral: "Make a referral for testing",
    popular_examinations: "The most popular test",
    package: "Package",
    added: "Added",
    number_of_referrals: "Number of referrals",
    average_number_of_referrals: "Average number of referrals",
    average_price_of_referral: "Average referral price",
    top_doctors: "Physicians who made referrals for the highest amount:",
    your_facility: "Collection point",
    choose_examinations: "Choose tests",
    additionally_examinations:
      "If you wish, you may additionally perform other tests",
    ordered_examinations: "Reffered tests",
    change: "Change the data and send the order again",

    add_visit_to_referral: "Would you like to add a visit to referral?",
    track_order_status: {
      title: "Track order status",
      order_paid: "Order paid",
      laboratory_received: "Laboratory Received",
      test_results_available: "Test results available",
      incomplete_results: "Incomplete Results",
      description_in_preparation_by_doctor:
        "Description in preparation by the doctor",
      description_of_test_results_sent:
        "Description of the test results sent to patient",
    },

    tabs: {
      packages: "Packages Tests",
      examinations: "Tests",
    },

    table: {
      id: "Referral ID",
      date: "Date",
      full_name: "Full name",
      identity_card: "ID card number",
      status: "Status",
      phone: "Phone number",
      number_of_examinations: "Number of tests",
      commission: "Salary",
      show: "See the referral",
      patient_card: "Patient's card",
      code: "Post Code",
      city: "City",
      street: "Street",
      price: "Price",
      pesel: "Pesel",
      patient_discount_percent: "Discount percentage",
      patient_discount: "Discount",
      upcoming_visits: "Upcoming visits",
      provision_percent: "provision%",
      provision: "provision",
    },
  },

  results: {
    download_pdf: "Download results in PDF",
    back_to_referral: "Back to referral",
    choose_examination: "Select a test to view results",
    lab: "Collection point",
    date_of_result_collection: "Date the result was collected",
    referral_number: "Referral number",
    examinations_for: "Test for",
    date_of_issue: "Date of refferal",
    doctor: "Doctor",
    doctor_referral: "Order from doctor",
    sport_referral: "Referral from sport",
    date_of_collection: "Date of test",
    home_visit_address: "Address of home visit",
    collection_point_service: "Collection point",
    order_no: "Order no.",
    all: "All",
    need_review: "Need review",
    expected_range: "Expected range",
    description_of_tests_during_preparation:
      "Description of tests during preparation",
    we_will_notify_you_by_SMS_when_they_are_ready:
      "We will notify you by SMS when they are ready.",
    recommendations: "Recommendations",
    make_an_appointment_to_discuss_the_results:
      "Make an appointment to discuss the results with your doctor",
    make_an_appointment: "Make an appointment",
    description_titles: {
      follow_up_plan: "Follow-Up Plan",
      lifestyle_modifications: "Lifestyle modifications",
      summarize_for_patient_communication:
        "Summarize for patient communication",
      results: "Results",
      health_risk_assessment: "Health risk assessment",
    },
  },

  select_time: {
    current_month: "Current month",
    previous_month: "Previous month",
    last_90_days: "The last 90 days",
    last_6_months: "Last 6 months",
    current_year: "Current year",
    selected_from: "Select from calendar",
    select_period: "Select period",
  },

  titles: {
    your_finances: "Your finances",
    your_stats: "Your statistics",
    stats: "Statistics",
    top_doctors: "Top Doctors",
  },

  your_finances: {
    to_pay: "To pay",
  },

  your_referrals: {
    see_results: "See test results",
    pay: "Pay for your tests",
    see_summary: "See summary",
    just_paid: "You have already paid for this referral.",
    go_to_facility: "Go to the collection center to get your tests done.",
  },
  documents: {
    terms: "Terms of service",
    privacy_policy: "Privacy Policy",
    providers_terms_of_use: "Providers Terms of Use",
  },

  your_settlements: {
    user_full_name: "First and last name",
    address: "Address",
    billing_data: "Invoice data",
    edit_billing_data: "Edit invoice details",
    my_settlements: "My settlements",
    verify_data_title: "Billings information",
    verify_data_text:
      "Enter billing information to be able to bill wages on referrals issued",
    company_data_title: "Company data",
    company_name: "Account Holder's Name",
    sort_code: "Sort Code",
    bank_name: "Bank Name",
    nip: "EIP",
    free_from_vat_title: "Please indicate if your services are VAT-exempt.",
    free_from_vat: "VAT Exempt",
    vat_registered: "VAT Registered",
    vat_unsure: "Unsure / Will Confirm",
    address_title: "Address",
    street: "The primary street address",
    house_number: "Optional details like suite, unit, building, floor.",
    flat_number: "Premises no",
    postal_code: "Postcode",
    city: "City/Town",
    country: "Country",
    account_number_title: "Bank account number",
    account_number: "Bank account number",
    verify_account: "Verify your account",
    your_invoices: "Your invoices",

    checkboxes: {
      check_all: "Select all",
      agree_terms: "I declare that I have read and accept",
      terms: "Terms",
      marketing:
        "I agree to the sending of commercial and marketing information via electronic means of communication.",
      agree3:
        "Donec dolor mi, interdum vel arcu vel, gravida sollicitudin est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed accumsan dui et sollicitudin porttitor",
      agree4:
        "Donec sed lacus leo. Fusce fermentum sem sed odio luctus, eu sollicitudin massa elementum. Maecenas pellentesque ligula libero, vel condimentum sem lobortis et",
    },

    invoice: {
      date: "Date",
      number: "Invoice number",
      amount: "Amount",
      download: "Download the invoice",
    },
    billing: "Billing",
  },

  share: {
    patient_examinations: "Patient tests",
    approvals: "Consents",
    ask_for_access: "Ask for access to test results",
  },

  your_stats: {
    issued_referrals: "Referrals issued",
    paid_referrals: "Paid referrals",
    completed_referrals: "Completed referrals",
    canceled_referrals: "Cancelled referrals",
    commission: "Salary",
    cost: "Cost of testing",
  },
  payment: {
    made: "Payment made.",
    canceled: "Payment cancelled.",
    advance: "Waiting for payment confirmation.",
    returned: "Payment returned.",
    go_to_dashboard: "Go to main panel",
    retry_payment: "Re-payment",
  },

  category: {
    bundles_categories: "Package categories",
    examinations_categories: "Tests categories",
  },

  collection_points: {
    filters: {
      is_children: "Child friendly",
      is_40_plus: "Prevention medicine for people over 40",
      is_ginecology: "Gynecological",
      is_sport: "GetLabTest Sport",
      is_swab: "Swab collection",
      is_parking: "Next to a lab",
      open_sunday: "Open Sundays",
      open_saturday: "Open Saturdays",
      covid_19: "Adapted for COVID19",
    },
    additional_info: "Additional information",
    additional_open: "additional_open",
    additional_close: "additional_close",
  },

  tests_to_describe: {
    how_much_time_you_have: "How much time you have",
    min: "{value} min",
    more: "More",
    tests_to_describe: "Tests to describe",
    orders_can_be_taken_by_others:
      "All the orders you have issued for patient will appear here and they will be ready for description as soon as the results go into the system.",
    status: "Status",
    id_order: "ID order",
    date: "Date",
    age_gender: "Age/Gender",
    due_time: "Due time",
    package: "Package",
    test_review_needed: "Test need review",
    salary: "Salary",
    listing_of_tests: "Listing of tests",
    see_patient_survey: "See patient survey",
    issue_referral_checkup: "Issue a referral for a checkup",
    description_of_test_results: "Description of test results",
    next_patient: "Next patient",
    previous_patient: "Previous patient",
    basic_information: "Basic information",
    save_as_a_draft: "Save as a draft",
    save_to_patient: "Send to patient",
    gender: "Sex",
    age: "Age",
    weight: "Weight",
    height: "Height",
    travelled_to_south_america_or_africa_in_last_12_months:
      "Have you travelled to South America, Central America, Asia or Africa in the last 12 months?",
    diseases_in_the_family: "Diseases in the family",
    lifestyle: "Lifestyle",
    frequency_of_drinking_alcohol: "Frequency of drinking alcohol",
    smoking: "Smoking",
    diet: "Diet",
    supplementation: "Supplementation",
    what_measles_takes: "What measles takes",
    mental_health_and_well_being: "Mental health and well-being",
    what_patient_feels: "What the patient feels",
    your_welcome_message: "Your welcome message",
    your_summary: "Your summary",
    good_news: "Good news",
    nothing_is_happening: "Nothing is happening",
    bad_news: "Bad news",
    very_bad_news: "Very bad news",
    new_type_of_message: "New type of message",
    when_tests_good_results: "When the tests give good results",
    when_nothing_happening: "When nothing is happening",
    when_tests_bad_results: "When the tests give bad results",
    generate_test_results_desc:
      "Generate descriptions of laboratory test results",
    summarize_patient_profile_desc: "Summarize Patient Profile",
    generate_next_tests: "Generate next tests to be performed",
    generate_health_risk_assessment: "Generate Health Risk Assessment",
    generate_disease_desc: "Generate a description of the disease",
    create_follow_up_plan: "Create Follow-Up Plan",
    generate_recommendations: "Generate recommendations",
    prescribe_lifestyle_modifications: "Prescribe Lifestyle Modifications",
    summarize_for_patient_communication: "Doctor's Notes",
    show_ai_text: "Show AI type text",
    close_ai_text: "Close AI type text",
    package_name: {
      individual: "Individual",
      extended_package: "Extended package",
      basic_package: "Basic package",
    },
    you_dont_have_tests_to_describe: "You don’t have any test to describe yet.",
    issue_a_referral_will_appear_here_for_description:
      "Issue a referral, and the results of the tests will appear here for description",
    hints: {
      profile:
        "Provides a concise overview of the patient's health based on their responses to the health questionnaire. It includes key personal health indicators, medical history, and any noted symptoms or concerns.",
      results:
        "Interprets and explains laboratory test results in an easy-to-understand format. Highlights significant findings, notes any abnormalities, and offers a general understanding of what these results might suggest about the patient's health.",
      health_risk_assessment:
        "Evaluates the patient's risk for various health conditions based on their medical history, lifestyle factors, and lab results. Identifies potential health risks and provides insights for preventive health measures.",
      follow_up_plan:
        "Outlines recommended next steps in the patient's care, including scheduling future appointments, additional tests, or referrals to specialists. Tailored to the patient's current health status and recent medical findings.",
      lifestyle_modifications:
        "Suggests changes in diet, exercise, and other lifestyle factors to improve the patient’s overall health. These personalized recommendations are based on the patient’s medical and lifestyle information.",
      summarize_for_patient_communication:
        "Translates medical findings and recommendations into a patient-friendly summary. This easy-to-understand overview helps patients grasp their health status and what actions they should take next.",
    },
  },
  create_profile: {
    create: "Create a profile",
    description:
      "Thanks to it, patients from all over Poland will be able to schedule online appointments with you and more.",
    synchronized_calendar: "Synchronized calendar",
    set_schedule: "Set schedule",
  },
  doctor_dashboard: {
    have_a_nice_day: "Have a nice day, {name}",
    you_still_have: "You still have {value} visits today",
    check_your_email_to_confirm:
      "Check your email {email} to confirm your account.",
    appointment_today: "Appointment today",
    test_to_describe: "Test to describe",
    make_a_referal: "Make a referal",
    clinic_revenue: "The revenue of the clinic",
    your_revenue: "Your revenue",
    new_patient: "New patient",
    final_stretch: "You're in the final stretch now",
    vs_last_month: " vs last month",
    tests_to_describe: "Tests to describe",
    see_all: "See all",
    show_less: "Show less",
    due_time: "Due time",
    test_need_review: "test need review",
    provision: "Provision",
    start_a_quick_visit: "Start a quick e-visit",
    visit_description: "Visit without patient registration in the system",
    start_consultation: "Start consultation",
    upcoming_appointments: "Upcoming appointments",
    add_new_appointment: "add new appointment",
    no_appointments: "You don't have any appointments yet. ",
    add_first_appointment: "Add your first appointment.",
    no_tests: "You don't have any test to describe",
    make_your_first_referral: "Make your first referral ",
    paid_referrals: " of which {value} are paid",
    no_referrals: "You haven't issued any referrals yet",
    start_working_with_referrals:
      "Start working with the system and issue your first referral",
    no_patients_1: "You don’t have any assigned patients yet.",
    no_patients_2: "Start working with the system and issue your patient.",
    no_tests_1: "You don’t have any test to describe yet.",
    no_tests_2:
      "Issue a referral, and the results of the tests will appear here for description",
  },
  patient_card: {
    add_appointment: "Add Appointment",
    patient_information: "Patient's information",
    patient_appointments: "Appointments",
    patient_history: "Referral History",
    information: {
      admin_information: "Admin information",
      personal_information: "Personal information",
      full_name: "Full name",
      gender: "Gender",
      date_of_birth: "Date of birth",
      contact_details: "Contact details",
      phone_number: "Phone number",
      email: "E-mail",
      woman: "Woman",
      man: "Man",
      status: "Status",
      roles: "Roles",
    },
    appointments: {
      table: {
        date: "Date",
        hours: "Hours",
        type_of_service: "Type of service",
        status: "Status",
        referral: "Referral",
      },
      service: "Service",
    },
  },
  quiz: {
    sex: {
      male: "Man",
      female: "Woman",
    },
    answer: {
      true: "Yes",
      false: "No",
      not_to_say: "Prefer not to say",
    },
  },

  specialization_services: {
    add_more_services: "Add more services",
    add: "Add service",
    fields: {
      name: "Service name",
      time: "Time",
      price: "Price",
    },
  },

  time: {
    mins0: "Continuously",
    mins2: "2 minuts",
    mins5: "5 minuts",
    mins10: "10 minuts",
    mins15: "15 minuts",
    mins30: "30 minuts",
    mins45: "45 minuts",
    hour: "1 hour",
  },

  week_days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },

  referral_type: {
    doctor: "Doctor",
    home: "Home Service",
    all: "All",
  },

  visit_type: {
    video: "Video",
    phone_call: "Phone call",
    offline: "Offline",
    all: "All",
  },

  settings: {
    services: {
      title: "Services",
      confirm_delete_service: "Are you sure you want to delete service?",
      confirm_delete_button: "Delete",
      edit_btn: "Edit",
      remove_btn: "Delete",
      add_next_service: "Add more services",
    },
    specializations: {
      main_specialty: "main specialty",
      edit_specialty: "Edit specialty",
      add_next: "Add next specialty",
      add: "Add specialty",
      yes_delete: "Yes, delete",
      yes_change: "Yes, change",
      set_as_main: "set as a main specialty",
      are_you_sure_change: "Are you sure you want to change specialty",
      are_you_sure_delete: "Are you sure you want to delete specialty",
      change_will_result_removal_services:
        "The change will result in the <b>removal of all assigned services</b>. Already scheduled appointments will still have to take place. The last scheduled appointment is on",
      delete_will_result_removal_services:
        "The change will result in the removal spacialitya and of all assigned services. <b>Already scheduled appointments will still have to take place.</b> The last scheduled appointment is on 20.11.2023.",
    },
    sms_notifications: {
      patient_notifications: "Patient notifications",
      activate_to_send_automtic_notifications:
        "Activate the service to send automatic notifications to patients about appointments.",
      notification_types: "Notification types",
      booked_appointments:
        "Booked appointments - as soon as you make an appointment",
      reminder_about_the_visit:
        "Reminder about the visit - 24 hours before the visit",
      changing_the_date: "Changing the date of the visit",
      canceled_appointment: "Canceled appointment",
      occasional_sms: "Occasional SMS",
      occasional_sms_info:
        "A message you can send to a selected group of patients to inform them about a time-sensitive situation or to extend holiday greetings.",
      create_a_message: "Create a message",
      sms_templates: "SMS templates",
      titles: {
        booked_appointment: "Booked appointments",
        reminder: "Reminder about the visit",
        changing_the_date_of_visit: "Changing the date of the visit",
        canceled_appointment: "Canceled appointments",
      },
      buttons: {
        date: "Date",
        hours: "Hours",
        doctor: "Doctor",
        link_to_appointment: "Link to appointment",
      },
      add_content: "Add content",
      occasional_sms_options: {
        all_patients: "All patients",
        patients_with_tomorrow_meetings: "Patients scheduled for tomorrow",
        patients_with_today_meetings: "Patients scheduled for today",
      },
    },
    appointment_hours: {
      current: "Current schedule",
      add_new_schedule: "Add new patient appoitments hours",
      changes_schedule_dates:
        "Since when do you want to make changes to the schedule",
      changes_can_cause_problems_with_visits:
        "Changes can cause problems with visits.",
      farthest_visit: "The farthest visit on",
      is_effective_from: "Is effective from",
      future_schedule: "Future schedule",
      is_active_to: "Is active to",
      new_schedule: "New schedule",
      new_schedule_info:
        "The new schedule will overwrite your current schedule",
    },
  },

  video: {
    waiting_room: "Waiting room",
    invite_by_email: "Invite by e-mail",
    patient_email_address: "Patient's  e-mail address",
    invite_by_phone_number: "Invite by phone number",
    loading: {
      title: "Waiting for connection",
      invite_title: "Please invite the patient",
      invite_subtitle: "Send him the link via email, SMS, or another channel.",
    },
    microphone: "Microphone",
    speaker: "Speaker",
    camera: "Camera",
    join_to_meeting: "Join",
    no_device: "Empty",
  },

  meet: {
    appointment: "Appointment",
    show_patient_card: "View patient card",
    issue_referral_for_testing: "Issue a referral for a test",
    approve_visit_card: "Approve appointment card",
    navigation: {
      video: "Video",
      interview: "Patient interview",
      results: "Test results",
      research: "Diagnosis",
      recommendations: "Recommendations",
      phone: "Phone consultation",
      patient_survey: "Patient Survey",
    },
  },

  doctor_call: {
    chat: {
      messages: "Messages",
      doctor: "You",
      patient: "Patient",
      response: "Answer",
    },
    saved: {
      card: "Appointment card saved.",
      diagnosis: "Diagnosis saved.",
      interview: "Interview saved.",
      recommendations: "Recommendations saved.",
    },
    not_saved: {
      interview: "Interview not saved.",
      recommendations: "Recommendations not saved.",
    },
    add_referral_for_test: "Add referral for a test",

    phone_status: {
      started: "Started",
      busy: "Busy",
      failed: "Failed",
      ringing: "Ringing",
      timeout: "Timed out",
      answered: "Answered",
      unanswered: "Unanswered",
      completed: "Completed",
      rejected: "Rejected",
    },
    connect: "Connect",
    visit_will_begin: "The visit will begin in",
    sure_end_visit: "Do you want to end your visit? ",
    one_you_end_warning:
      'Once you end, the patient can\'t rejoin the appointment. To leave briefly and come back, choose "disconnect."',
  },

  staff: {
    create: "Add staff",
    search: "Find Member",
    back_to_list: "Back to Staff List",
    profile_overview: "Profile Overview",
  },

  calendar: {
    edit_visit: "Edit an appointment",
    cancel_appointment: "Cancel appointment",
    basic_info: "Basic information",
    patient_data: "Patient's data",
    service_selected_by_the_patient: "Service selected by the patient",
    appointment_date: "Appointment date",
    slots_time: {
      until_noon: "Until noon",
      afternoon: "Afternoon",
      evening: "Evening",
    },
  },

  medical_entity: {
    completed: {
      facility_ready: "Your facility is ready!",
      ready_description:
        " Make the most of it by adding staff and completing facility profiles, so patients from all over word can book appointments with your clinic online ",
      add_doctor: "Add doctor",
      complete_profile: "Complit a facility profile",
      add_provider: "Add provider  to facilty",
    },
    add_staff: "Add Staff",
  },

  visits: {
    status: {
      waiting_for_results: "Waiting for results",
      canceled_by_doctor: "Canceled by Doctor",
      to_be_scheduled: "To be Scheduled",
      no_show: "No show",
      rescheduled: "Rescheduled",
      scheduled: "Scheduled",
      completed: "Completed",
      ongoing: "Ongoing",
      waiting_for_payment: "Waiting for payment",
    },
    track_status: {
      paid: "Order paid",
      scheduled: "Scheduled",
      ongoing: "Ongoing",
      completed: "Completed",
      canceled_by_doctor: "Canceled by Doctor",
      no_show: "No show",
    },
    type: {
      video: "Video",
      "phone-call": "Call",
    },
  },
};
